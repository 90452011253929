import project1 from '../assets/project1.png';
import project2 from '../assets/project2.png';
import project3 from '../assets/project3.png';

const PROJECTS = [
  {
    id: 1,
    title: 'Options Modeling',
    description: 'A machine model of stock options.',
    link: 'https://github.com/dinu-merca/options-modeling',
    image: project1
  },
  {
    id: 2,
    title: 'Example React Application',
    description: 'A sample React App that I built',
    link: 'https://github.com/dinu-merca/react-example',
    image: project2
  },
  {
    id: 3,
    title: 'REST APIs',
    description: 'A simple REST API with GET and POST',
    link: 'https://github.com/dinu-merca/rest-api-example',
    image: project3
  }
];

export default PROJECTS;
