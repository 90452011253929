import React, { Component } from 'react';
import Projects from './Projects';
import profile from './assets/profile.png';

class App extends Component {

    state = { displayBio: false };

    toggleDisplayBio = () => {
        this.setState({ displayBio: !this.state.displayBio });
    }

    render() {
        let bio = this.state.displayBio ? (
            <div>
                <p>I was born in Romania (in Eastern Europe), in a beautiful small city called Timisoara,
                   in a region with a lot of history, and very friendly people. I studied computer science
                   at the local technical university, and after graduating, and working for a couple of
                   years in Romania, I moved to Toronto, where, except for a short 2 years contract in
                   Austin Texas, I've been living since then.</p>
                <p>I really enjoy engineering and technology, and spend a lot of time reading about software
                   and also developing software.</p>
                <button onClick={this.toggleDisplayBio}>Show less</button>
            </div>
        ) : (
            <div>
                <button onClick={this.toggleDisplayBio}>Read more</button>
            </div>
        );

        return (
            <div>
                <img src={profile} alt='profile' className='profile' />
                <h1>Hello</h1>
                <p>My name is Dinu. I am a software engineer living in Toronto, Canada</p>
                <p>My favorite programming languages are Java and JavaScript</p>
                <p>I also develop software architecture, most recently focusing on APIs and Events Driven Architecture</p>
                {bio}
                <hr />
                <Projects />
            </div>
        )
    }
}

export default App;
